const About = () => (
    <div id="about" className="row pt-5">
        <h6 className="fw-bold">ABOUT</h6>
        <p className="pt-4">
            I'm a Software Engineering student with a background as an IT Technician, set to complete my Bachelor's degree in Spring 2025. I love tackling complex challenges and continuously expanding my skill set. I am actively seeking opportunities to contribute and grow professionally.
        </p>
        <p>
            Through my studies and hands-on projects, I've gained experience with a range of technologies, including:
        </p>
        <p>
            Backend: C#/.NET, Kotlin/Java/Spring Boot, SQL
            <br/>Frontend: JavaScript, React, Bootstrap, CSS/HTML
            <br/>DevOps: Docker, Azure, Git, Linux/Bash, Windows/Powershell
        </p>
        <img className="mt-5 d-md-none col-10 col-sm-8 mx-auto rounded-circle" src="/profile.JPEG" alt="Vetle L. Gundersen" />
    </div>
);

export default About;
